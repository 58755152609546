'use strict'
import Inputmask from "inputmask";

Inputmask('+373 9999-9999').mask(document.getElementById('phone'));

window.addEventListener('DOMContentLoaded', function () {
    let openMenu = document.getElementById('open-menu'),
        closeMenu = document.getElementById('close-menu'),
        menu = document.getElementById('menu'),
        body = document.getElementsByTagName('BODY')[0];

    openMenu.addEventListener('click', (e) => {
        menu.classList.add('transform-menu')
        body.classList.add('overflow-y-hidden')
    })

    closeMenu.addEventListener('click', (e) => {
        menu.classList.remove('transform-menu');
        body.classList.remove('overflow-y-hidden')

    })
})
